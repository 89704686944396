import React from 'react';

function PrivacyPolicy() {
    return (
        <section>
            <h2>Loop Weaver Privacy Policy</h2>
            <p><strong>Privacy Policy for Loop Weaver</strong></p>
            <p>
                Loop Weaver is a free-to-play game created as a passion project. It does not collect, store, or share any personal data from its users.
            </p>
            <p><strong>Advertising and Data Collection</strong></p>
            <ul>
                <li>This game is 100% free and does not display ads.</li>
                <li>No personal information is required to play or access the game.</li>
            </ul>
            <p><strong>Contact Information</strong></p>
            <p>
                If you have any questions about this Privacy Policy, please contact us at:
                <a href="mailto:will@pixelfad.com"> will@pixelfad.com</a>.
            </p>
        </section>
    );
}

export default PrivacyPolicy;
