import React from 'react';

function Homepage() {
    return (
        <section>
            <h2>Welcome to PixelFad</h2>
            <p>Your trusted partner for innovative digital solutions. At PixelFad LLC, we specialize in cutting-edge design and development tailored to your needs.</p>
        </section>
    );
}

export default Homepage;
