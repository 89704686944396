import React from 'react';

function ContactPage() {
    return (
        <section>
            <h2>Contact Us</h2>
            <p>We would love to hear from you! Reach out to us for inquiries or collaborations:</p>
            <ul>
                <li>Email: will@pixelfad.com</li>
                <li>Phone: (425) 780-2250</li>
            </ul>
        </section>
    );
}

export default ContactPage;
